<!--
 * @Description: 停车管理--停车套餐管理--列表页面
 * @Author: 小广
 * @Date: 2019-06-04 14:06:49
 * @LastEditors: 小广
 * @LastEditTime: 2019-06-28 14:34:21
 -->
<template>
  <div class="parkPackageManageList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增套餐" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
        ></v-select2>
        <v-select
          label="租赁方式"
          v-model="searchParams.rentType"
          :options="rentTypeOps"
        ></v-select>
        <v-select
          label="套餐类型"
          v-model="searchParams.suitType"
          :options="suitTypeOps"
        ></v-select>
        <v-select
          label="状态"
          v-model="searchParams.status"
          :options="statusOps"
        ></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="update"
          @click="edit(scope.row)"
        ></v-button>
        <v-button
          v-if="scope.row !== 2"
          text="删除"
          type="text"
          permission="update"
          @click="deleteHandle(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getParkPackageListURL, getParkPackageOrderedURL, deleteParkPackageURL } from './api'
import {
  statusOps,
  statusMap,
  rentTypeOps,
  rentTypeMap,
  suitTypeOps,
  suitTypeMap,
  orientTypeMap
} from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'ParkPackageManageList',
  data () {
    return {
      searchUrl: getParkPackageListURL,
      statusOps: statusOps.slice(0, -1),
      rentTypeOps: rentTypeOps,
      suitTypeOps: suitTypeOps.slice(0, -1),
      communityParams,
      searchParams: {
        communityId: '',
        status: undefined,
        rentType: undefined,
        suitType: undefined
      },
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'suitName',
          label: '套餐名称',
          align: 'left'
        },
        {
          prop: 'suitTypeStr',
          label: '套餐类型',
          formatter (row) {
            return suitTypeMap[row.suitType]
          }
        },
        {
          prop: 'price',
          label: '套餐价格',
          align: 'right'
        },
        {
          prop: 'isAudit',
          label: '是否需审核',
          align: 'right',
          formatter (row) {
            if (row.isAudit === 0) {
              return '否'
            } else if (row.isAudit === 1) {
              return '是  '
            }
          }
        },
        // {
        //   prop: 'totalPark',
        //   label: '车位数量',
        //   align: 'right'
        // },
        // {
        //   prop: 'availablePark',
        //   label: '剩余车位数量',
        //   align: 'right'
        // },
        {
          prop: 'orientTypeStr',
          label: '开放对象',
          formatter (row) {
            return orientTypeMap[row.orientType]
          }
        },
        {
          prop: 'rentTypeStr',
          label: '租赁方式',
          formatter (row) {
            let zoneName = row.zoneName ? `-${row.zoneName}` : ''
            return `${rentTypeMap[row.rentType]}${zoneName}`
          }
        },
        {
          prop: 'statusStr',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        }
      ]
    }
  },
  methods: {

    async deleteHandle (row) {
      // 删除的操作
      let isOk = await this.$confirm('确认删除？', { title: '提示' })
      isOk && this.requestForIsOrdered(row)
    },

    // 查询是否有预定
    requestForIsOrdered (row) {
      let _this = this
      let requestUrl = `${getParkPackageOrderedURL}/${row.id}/isOrdered`
      this.$axios.get(requestUrl).then(res => {
        if (res.status === 100) {
          if (res.data) {
            _this.alertForIsOrdered(row)
          } else {
            _this.requestForDelete(row)
          }
        }
      })
    },

    // 有预定的提示
    async alertForIsOrdered (row) {
      let message = '此类型车位已有用户预订，是否删除，删除后用户将不能申请，若有冲突请线下与用户协商解决。'
      let isOk = await this.$confirm(message, { title: '提示' })
      isOk && this.requestForDelete(row)
    },

    // 删除的请求
    requestForDelete (row) {
      let _this = this
      let requestUrl = `${deleteParkPackageURL}/${row.id}`
      this.$axios.delete(requestUrl).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message({
            type: 'success',
            message: '删除成功',
            center: true
          })
        }
      })
    },

    create () {
      this.$router.push({
        name: 'parkPackageManageForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'parkPackageManageForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
